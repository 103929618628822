import type { ZonesGetAllResponse } from '@ttc/api/zones'
import AuthContext from 'AuthContext'
import { apiRequest, getApiBaseUrl } from 'api'
import type {
  TableCellChangeEventProps,
  TableCellRenderProps,
} from 'app/components/Table/types'
import { DRAFTPURCHASEORDERS_READWRITE } from 'caps'
import { CellProductName } from 'components'
import {
  Table,
  defaultCellRenderer,
  setCellLoading,
  setEditCell,
  setRow,
  setTable,
  useTable,
  useTableApi,
  useTableColumns,
} from 'components/Table'
import type { Column } from 'components/Table/reducer/types'
import ManageColumnsPanel from 'containers/common/ManageColumnsPanel'
import { type ApiCall, usePanelControl, usePrevious } from 'hooks'
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { Button } from 'reactstrap'
import Swal from 'sweetalert2'
import { getStorage, showError, uiApiRequest } from 'utils'
import ZoneCell from '../DeliveriesTable/TruckItemsTable/ZoneCell'
import DoNotListButton from './DoNotListButton'
import { getColumnDef } from './columnDef'

const storageKey = 'editPurchaseOrder'
const [tableSetItem, tableGetItem] = getStorage(storageKey)

type OverviewTableProps = {
  id: number
  reloadKey: number
  onChange: () => void
  onClickProductName: (props: {
    productId: number
    parentProductId: number
    imageUrl: string
    name: string
  }) => void
  readOnly: boolean
  editProductsPanel: any
  onClickPrintOverview: () => void
  apiLoadZones: ApiCall<ZonesGetAllResponse>
  isDraftsView: boolean
}

const OverviewTable = (props: OverviewTableProps) => {
  const { hasCap } = useContext(AuthContext)
  const canEditCost = hasCap(DRAFTPURCHASEORDERS_READWRITE)

  const {
    reloadKey,
    onChange,
    readOnly,
    editProductsPanel,
    onClickProductName,
    onClickPrintOverview,
    apiLoadZones,
    isDraftsView,
  } = props

  const columnDef = useMemo(() => {
    return getColumnDef(readOnly, isDraftsView, canEditCost)
  }, [readOnly, isDraftsView, canEditCost])

  const [tableState, tableDispatch] = useTable({
    cacheKey: `orderItemsTable_${props.id}`,
    getItem: tableGetItem,
    setItem: tableSetItem,
  })
  const { sortColumn, sortAsc } = tableState
  const [tableTriggerSearch] = useTableApi(
    'purchaseOrders_getOrderItems',
    tableState,
    tableDispatch,
    { id: props.id },
  )

  const prevReloadKey = usePrevious(reloadKey)
  useEffect(() => {
    if (reloadKey === prevReloadKey) {
      return
    }

    tableTriggerSearch()
  }, [prevReloadKey, reloadKey, tableTriggerSearch])

  const handleClickCell = useCallback(
    (rowId: string, colId: string) => {
      tableDispatch(setEditCell(rowId, colId))
      return false
    },
    [tableDispatch],
  )

  const handleChangeCell = useCallback(
    async (props: TableCellChangeEventProps) => {
      const { value, oldValue, colId, rowId } = props

      const apiCall = {
        qty: 'purchaseOrderItems_setQty',
        cost: 'purchaseOrderItems_setCost',
        listed: 'purchaseOrderItems_setListed',
      }

      if (apiCall[colId] == null) {
        return
      }

      try {
        tableDispatch(setCellLoading(rowId, colId))

        const row = await apiRequest({
          action: apiCall[colId],
          id: rowId,
          value,
          oldValue,
        })

        tableDispatch(setRow(rowId, row))
        tableDispatch(setCellLoading(null))
        tableTriggerSearch(true)

        if (onChange) {
          onChange()
        }
      } catch (e) {
        tableDispatch(setCellLoading(null))
        showError(e.message)
        tableTriggerSearch(true)
      }
    },
    [onChange, tableDispatch, tableTriggerSearch],
  )

  const handleClickDeleteProduct = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()

      const id = e.currentTarget.getAttribute('data-id')

      const ret = await Swal.fire({
        icon: 'question',
        text: 'Are you sure you want to delete this product from the purchase order?',
        showCancelButton: true,
        showCloseButton: true,
      })

      if (ret.value) {
        await uiApiRequest({
          action: 'purchaseOrderItems_trashOrderItem',
          id,
        })
        tableTriggerSearch(true)

        if (onChange) {
          onChange()
        }
      }
    },
    [tableTriggerSearch, onChange],
  )

  const handleClickListAll = useCallback(
    async (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()

      const rowId = e.currentTarget.getAttribute('data-id')
      const orderedQty = e.currentTarget.getAttribute('data-ordered-qty')
      const listedQty = e.currentTarget.getAttribute('data-listed-qty')

      try {
        tableDispatch(setCellLoading(rowId, 'listed'))

        const row = await apiRequest({
          action: 'purchaseOrderItems_setListed',
          id: rowId,
          value: orderedQty,
          oldValue: listedQty,
        })

        tableDispatch(setRow(rowId, row))
        tableDispatch(setCellLoading(null))
        tableTriggerSearch(true)

        if (onChange) {
          onChange()
        }
      } catch (e) {
        tableDispatch(setCellLoading(null))
        showError(e.message)
        tableTriggerSearch(true)
      }
    },
    [tableDispatch, tableTriggerSearch, onChange],
  )

  const zones = useMemo(
    () => apiLoadZones.result?.rows || [],
    [apiLoadZones.result],
  )

  const renderCell = useCallback(
    (props: TableCellRenderProps) => {
      if (props.col.id === 'product') {
        const { value, row } = props

        return (
          <CellProductName
            {...{
              ...row.product_data,
              onClick: onClickProductName,
              value,
            }}
          />
        )
      }
      if (props.col.id === 'actions') {
        return (
          <td>
            <DoNotListButton
              {...{ readOnly, tableTriggerSearch, onChange, row: props.row }}
              className="mr-4"
            />
            {!readOnly ? (
              <a
                data-id={props.row.id}
                href="#"
                onClick={!readOnly ? handleClickDeleteProduct : null}
                className="mr-4"
                title="Remove product"
              >
                <i
                  style={{ verticalAlign: 'middle' }}
                  className="fa fa-trash"
                />
              </a>
            ) : null}
            {!readOnly ? (
              <a
                data-id={props.row.id}
                data-col-id={props.col.id}
                data-ordered-qty={props.row.qty}
                data-listed-qty={props.row.listed}
                href="#"
                onClick={!readOnly ? handleClickListAll : null}
                title="List all ORDERED qty"
              >
                <i
                  style={{ verticalAlign: 'middle' }}
                  className="fa fa-check"
                />
              </a>
            ) : null}
          </td>
        )
      }
      if (props.col.id === 'zones') {
        return (
          <ZoneCell
            {...{
              product: props.row.product_data,
              zones,
              zoneQty: props.value,
            }}
          />
        )
      }

      return defaultCellRenderer(props)
    },
    [
      tableTriggerSearch,
      onChange,
      onClickProductName,
      handleClickDeleteProduct,
      handleClickListAll,
      readOnly,
      zones,
    ],
  )

  const renderRowClassName = useCallback(
    ({ row }) =>
      row.product_data ? (row.do_not_list == 1 ? 'highlight' : '') : '',
    [],
  )

  const dropId = useRef<number>()

  const beginDrag = useCallback(() => {
    dropId.current = null
  }, [])

  const didDrop = useCallback(() => {
    const saveRowOrder = async () => {
      try {
        const _dropId = Math.random()
        dropId.current = _dropId

        const table = await apiRequest({
          action: 'purchaseOrders_setOrderItemsOrder',
          json: {
            id: props.id,
            sorted_ids: tableState.rows.map((row) => row.id),
          },
        })

        if (dropId.current === _dropId) {
          tableDispatch(setTable(table))
        }
      } catch (e) {
        showError(e.message)
        tableTriggerSearch(true)
      }
    }

    saveRowOrder()
  }, [props.id, tableState.rows, tableTriggerSearch, tableDispatch])

  const handleClickBulkEdit = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      editProductsPanel.open()
    },
    [editProductsPanel],
  )

  const handleClickExport = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()

      const format = (e.target as HTMLElement).getAttribute('data-format')

      const visibleColumns = columnDef.filter(
        (column: Column) => column.canExport !== false,
      )

      const params = {
        purchase_order_id: props.id,
        sortColumn,
        sortAsc,
        columns: visibleColumns,
      }

      const paramsEncoded = encodeURIComponent(JSON.stringify(params))

      let url = `${getApiBaseUrl()}/?action=treeadmin_purchaseOrders_export`
      url += `&params=${paramsEncoded}`
      url += `&format=${format}`

      if (e.shiftKey) {
        url = `${url}&text=1`
      }

      window.open(url)
    },
    [props.id, columnDef, sortColumn, sortAsc],
  )

  const manageColumnsPanel = usePanelControl()

  const columns = useTableColumns(
    storageKey,
    tableState,
    tableDispatch,
    columnDef,
  )

  return (
    <>
      <a
        href="#"
        onClick={manageColumnsPanel.toggle}
        style={{ position: 'absolute', right: 20, top: 45 }}
      >
        <i className="fa fa-columns" />
      </a>
      <ManageColumnsPanel
        {...{ columns }}
        isOpen={manageColumnsPanel.isOpen}
        onClose={manageColumnsPanel.close}
      />
      <Table
        border={false}
        onClickCell={handleClickCell}
        onChangeCell={!readOnly ? handleChangeCell : null}
        entityName="orders"
        hasPagination={false}
        hasDragDrop={tableState.sortColumn === null}
        {...tableState}
        {...{
          renderCell,
          renderRowClassName,
          setItem: tableSetItem,
          getItem: tableGetItem,
          columnDef,
          dispatch: tableDispatch,
          beginDrag,
          didDrop,
        }}
      />
      <Button
        className="ml-3 mt-3"
        onClick={onClickPrintOverview}
        color="primary"
      >
        Print
      </Button>
      <Button
        className="ml-3 mt-3"
        data-format="csv"
        onClick={handleClickExport}
        color="primary"
      >
        Export CSV
      </Button>
      <Button
        className="ml-3 mt-3"
        data-format="xlsx"
        onClick={handleClickExport}
        color="primary"
      >
        Export Excel
      </Button>
      {!isDraftsView && !readOnly ? (
        <Button
          className="ml-3 mt-3"
          onClick={handleClickBulkEdit}
          color="primary"
        >
          Edit Properties
        </Button>
      ) : null}
    </>
  )
}

export default OverviewTable
