import { encodeQueryString, getApiBaseUrl } from 'api'
import { useApi } from 'hooks'
import { useCallback, useMemo, useReducer } from 'react'
import { debugLog } from './../utils'

type State = {
  isLoading: boolean
  error: string | null
}

const initialState: State = {
  isLoading: false,
  error: null,
}

let fetchCache = {}

const fetchCached = async (url: string) => {
  if (Object.hasOwn(fetchCache, url)) {
    debugLog(`Fetched from cache: ${url}`)
    return fetchCache[url]
  }

  if (Object.keys(fetchCache).length > 10) {
    debugLog('Clear fetch cache')
    fetchCache = {}
  }

  let data = null

  debugLog(`Fetch start: ${url}`)
  const ret = await fetch(url, { credentials: 'include' })

  const ct = ret.headers.get('content-type')
  if (ct && ct.indexOf('application/json') === 0) {
    data = await ret.text()
    const json = JSON.parse(data)
    debugLog(`Fetch error ${json.error}`)
    throw new Error(json.error)
  }

  data = await ret.blob()
  fetchCache[url] = data

  debugLog(`Fetch success: ${url}`)
  return data
}

type Action =
  | { type: 'SET_LOADING'; isLoading: boolean }
  | { type: 'SET_ERROR'; error: string }

export const useLabelPrinter = ({ printQueue }) => {
  const [state, dispatch] = useReducer<React.Reducer<State, Action>>(
    (state, action) => {
      switch (action.type) {
        case 'SET_LOADING': {
          return { ...state, isLoading: action.isLoading, error: null }
        }
        case 'SET_ERROR': {
          return { ...state, isLoading: false, error: action.error }
        }
        default: {
          return state
        }
      }
    },
    initialState,
  )

  const apiGetLabelSplits = useApi(
    () => ({
      action: 'shipments_getLabelSplits',
    }),
    null,
    () => ({ throws: true }),
  )

  const printLabel = useCallback(
    async (orderNumber, labelId) => {
      try {
        dispatch({ type: 'SET_LOADING', isLoading: true })

        const splits = await apiGetLabelSplits.performRequest({
          json: {
            id: labelId,
          },
        })

        debugLog(`Splits: ${JSON.stringify(splits)}`)

        for (const name of splits) {
          const params = encodeQueryString({
            action: 'treeadmin_shipments_downloadLabelSplit',
            id: labelId,
            name,
          })
          const url = `${getApiBaseUrl()}?${params}`
          const data = await fetchCached(url)

          debugLog(`Sending to printer: ${url}`)

          printQueue.addJob({
            name: 'sendFileData',
            desc: `Print Label Order ${orderNumber} (${name})`,
            data,
          })
        }

        dispatch({ type: 'SET_LOADING', isLoading: false })
      } catch (e) {
        debugLog(`Error ${e.message}`)
        dispatch({ type: 'SET_ERROR', error: e.message })
      }
    },
    [printQueue, apiGetLabelSplits],
  )

  const printSlip = useCallback(
    async (orderNumber, orderSplitId, pages) => {
      try {
        dispatch({ type: 'SET_LOADING', isLoading: true })

        const params = encodeQueryString({
          action: 'treeadmin_orderSplits_printPackingSlips',
          zpl: '1',
          order_split_ids: orderSplitId,
          salt: String(Math.random()),
          pages,
        })
        const url = `${getApiBaseUrl()}?${params}`
        const data = await fetchCached(url)

        debugLog(`Sending to printer: ${url}`)

        printQueue.addJob({
          name: 'sendFileData',
          desc: `Print Slip Order ${orderNumber}`,
          data,
        })

        debugLog(`Print done: ${url}`)

        dispatch({ type: 'SET_LOADING', isLoading: false })
      } catch (e) {
        debugLog(`Error ${e.message}`)
        dispatch({ type: 'SET_ERROR', error: e.message })
      }
    },
    [printQueue],
  )

  return useMemo(
    () => ({
      isLoading: state.isLoading,
      printLabel,
      printSlip,
      error: state.error,
    }),
    [printLabel, printSlip, state.isLoading, state.error],
  )
}

export default useLabelPrinter
