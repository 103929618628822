import type { GetOrderApiParams, GetOrderApiResult } from '@ttc/api/orders'
import { LoadingImage, NotesList } from 'components'
import type { Row } from 'components/Table'
import { type StatefulState, useApi, usePrevious, useStateful } from 'hooks'
import React, { useCallback, useEffect, useRef } from 'react'
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap'

type DropDownState = {
  id?: string | null
  isEditing?: boolean
}

type NotesIconProps = {
  id: string
  orderId: string
  header: string
  editHeader?: string
  children: JSX.Element
  hasNotes: boolean
  dropDownState: StatefulState<DropDownState>
  value?: any
  updateRow: (orderId: string, row: Row) => void
  apiAction?: string
  readOnly: boolean
  isLoading: boolean
  reloadNotes: () => void
}

const NotesIcon = (props: NotesIconProps) => {
  const {
    orderId,
    header,
    editHeader,
    children,
    id,
    hasNotes,
    dropDownState,
    value,
    updateRow,
    apiAction,
    readOnly,
    isLoading,
    reloadNotes,
  } = props

  const canEdit = apiAction != null
  const notes = useStateful('')
  const isEditing = dropDownState.value.isEditing
  const isOpen = dropDownState.value.id === id
  const prevIsEditing = usePrevious(isEditing)
  const prevIsOpen = usePrevious(isOpen)
  const apiSave = useApi({}, null, { errorModal: true })

  /* Immediatly switch to edit mode if no notes exist yet. */
  useEffect(() => {
    if (!hasNotes && !isEditing && isOpen && isOpen !== prevIsOpen) {
      dropDownState.update({ isEditing: true })
    }
  }, [dropDownState, hasNotes, isOpen, prevIsOpen, isEditing])

  /* Initialize textarea content when switching to edit mode. */
  useEffect(() => {
    if (isOpen && isEditing && isEditing !== prevIsEditing) {
      notes.set(value)
    }
  }, [notes, value, isOpen, isEditing, prevIsEditing])

  /* Dismiss editing mode when popover closes. */
  useEffect(() => {
    if (isEditing && dropDownState.value.id == null) {
      dropDownState.update({ isEditing: false })
    }
  }, [isEditing, dropDownState])

  const handleToggle = useCallback(() => {
    if (isEditing || !canEdit) {
      return
    }

    dropDownState.update({ id: isOpen ? null : id })
  }, [isOpen, isEditing, dropDownState, id, canEdit])

  const handleClickIcon = useCallback(() => {
    if (readOnly || isLoading || !canEdit) {
      return
    }

    if (!isOpen) {
      dropDownState.update({ id })
      return
    }

    dropDownState.update({ isEditing: !isEditing })
  }, [readOnly, isOpen, isEditing, isLoading, dropDownState, id, canEdit])

  const handleClickSave = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault()

      if (readOnly || apiSave.isLoading) {
        return
      }

      const row = await apiSave.performRequest({
        action: apiAction,
        id: orderId,
        notes: notes.value,
      })
      if (row?.id) {
        updateRow(orderId, row)
      }
      dropDownState.update({ id: null, isEditing: false })

      reloadNotes()
    },
    [
      readOnly,
      apiAction,
      updateRow,
      orderId,
      notes,
      apiSave,
      dropDownState,
      reloadNotes,
    ],
  )

  const handleChangeTextarea = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      notes.set(e.currentTarget.value)
    },
    [notes],
  )

  const ref = useRef(null)

  return (
    <>
      <i
        ref={ref}
        className={`notes-icon fa fa-comment${hasNotes ? '' : '-o'} ml-1`}
        onClick={handleClickIcon}
      />
      {!isLoading ? (
        <UncontrolledPopover
          className={!isEditing ? 'hovering' : ''}
          placement="left"
          isOpen={isOpen}
          target={ref}
          trigger={hasNotes ? 'hover' : 'click'}
          toggle={handleToggle}
          key={isEditing ? `${id}-editing` : `${id}`}
        >
          <PopoverHeader>
            {!readOnly && isEditing ? (
              <>
                {editHeader}
                <a
                  className={`float-right ${
                    apiSave.isLoading ? 'disabled' : ''
                  }`}
                  href="#"
                  onClick={handleClickSave}
                >
                  Save
                </a>
              </>
            ) : (
              header
            )}
            {apiSave.isLoading ? <LoadingImage small className="ml-2" /> : null}
          </PopoverHeader>
          {isEditing ? (
            <PopoverBody>
              <textarea
                className="form-control"
                style={{ minHeight: 150 }}
                value={notes.value}
                onChange={handleChangeTextarea}
              />
            </PopoverBody>
          ) : (
            <PopoverBody
              style={{
                maxHeight: 300,
                overflow: 'hidden',
                whiteSpace: 'pre-wrap',
              }}
            >
              {children}
            </PopoverBody>
          )}
        </UncontrolledPopover>
      ) : null}
    </>
  )
}

type NotesCellProps = {
  id: string
  dropDownState: StatefulState<DropDownState>
  hasNotesFromBuyer: boolean
  hasNotesToBuyer: boolean
  hasNotesInternal: boolean
  updateRow: (id: string, row: Row) => void
  readOnly: boolean
}

const NotesCell = React.memo((props: NotesCellProps) => {
  const {
    id,
    dropDownState,
    hasNotesFromBuyer,
    hasNotesToBuyer,
    hasNotesInternal,
    updateRow,
    readOnly,
  } = props

  const getOrder = useApi<GetOrderApiResult, GetOrderApiParams>(
    {
      action: 'editOrder_getOrder',
      order_id: null,
    },
    {
      notesFromBuyer: '',
      notesToBuyer: [],
      notesInternal: [],
    },
  )
  const isLoading = getOrder.isLoading
  const { notesFromBuyer, notesToBuyer, notesInternal } = getOrder.result

  const reloadNotes = useCallback(() => {
    if (getOrder.params.order_id != id) {
      getOrder.reset()
    }

    getOrder.performRequest({ order_id: id })
  }, [getOrder, id])

  const dropDownStatePrev = usePrevious(dropDownState)
  useEffect(() => {
    if (
      dropDownStatePrev != dropDownState &&
      dropDownState.value.id &&
      dropDownState.value.id.indexOf(`notes-${id}`) === 0 &&
      !getOrder.hasResult &&
      !getOrder.isLoading
    ) {
      reloadNotes()
    }
  }, [dropDownState, dropDownStatePrev, id, getOrder, reloadNotes])

  return (
    <div className="order-notes-cell">
      <NotesIcon
        id={`notes-${id}-popover1`}
        header="Notes from the buyer"
        hasNotes={hasNotesFromBuyer}
        value={notesFromBuyer}
        {...{
          isLoading,
          readOnly,
          orderId: id,
          updateRow,
          dropDownState,
          reloadNotes,
        }}
      >
        {isLoading ? null : notesFromBuyer == '' ? (
          <i>(empty)</i>
        ) : (
          <>{notesFromBuyer}</>
        )}
      </NotesIcon>
      <NotesIcon
        id={`notes-${id}-popover2`}
        header="Notes to the buyer"
        editHeader="Add note to the buyer"
        hasNotes={hasNotesToBuyer}
        apiAction="orders_addOrderNote"
        {...{
          isLoading,
          readOnly,
          orderId: id,
          updateRow,
          dropDownState,
          reloadNotes,
        }}
      >
        {isLoading ? null : <NotesList notes={notesToBuyer} />}
      </NotesIcon>
      <NotesIcon
        id={`notes-${id}-popover3`}
        header="Internal notes"
        editHeader="Add internal note"
        hasNotes={hasNotesInternal}
        apiAction="orders_addInternalNote"
        {...{
          isLoading,
          readOnly,
          orderId: id,
          updateRow,
          dropDownState,
          reloadNotes,
        }}
      >
        {isLoading ? null : <NotesList notes={notesInternal} />}
      </NotesIcon>
    </div>
  )
})

export default NotesCell
