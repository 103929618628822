import { usePrevious } from '@ttc/hooks'
import AuthContext from 'AuthContext'
import { DRAFTPURCHASEORDERS_READWRITE } from 'caps'
import EditPanel from 'components/EditPanel'
import EditPanelCard from 'components/EditPanelCard'
import Loading from 'components/Loading'
import { poStatusOptions } from 'containers/ManagePurchaseOrders/options'
import useApi from 'hooks/useApi'
import type React from 'react'
import { useCallback, useContext, useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router'
import Select from 'react-select'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'

const linkTo = (id: number, orderStatus: string) => {
  const path =
    orderStatus === 'CONFIRMED' ? 'purchase-orders' : 'draft-purchase-orders'

  return `/${path}/edit/${id}`
}

type State = {
  invoice_number: string
  order_date: string
  discount: string
  order_status: string
}

const initialState: State = {
  invoice_number: '',
  order_date: '',
  discount: '',
  order_status: null,
}

type Action = { type: 'SET_VALUES'; values: Partial<State> }

const SET_VALUES = 'SET_VALUES'
const setValues = (
  values: Partial<State>,
): { type: 'SET_VALUES'; values: Partial<State> } => {
  return { type: SET_VALUES, values }
}

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case SET_VALUES: {
      return { ...state, ...action.values }
    }
    default:
      return state
  }
}

type OrderDetailsEditorProps = {
  isOpen: boolean
  onClose: (isSaved?: boolean) => void
  editId: number | null
}

const OrderDetailsEditor = (props: OrderDetailsEditorProps) => {
  const navigate = useNavigate()

  const { hasCap } = useContext(AuthContext)
  const canEditDiscount = hasCap(DRAFTPURCHASEORDERS_READWRITE)

  const { isOpen, onClose, editId } = props
  const [state, dispatch] = useReducer<React.Reducer<State, Action>>(
    reducer,
    initialState,
  )

  const getRowApi = useApi(
    () => ({ action: 'purchaseOrders_getRow' }),
    null,
    () => ({ throws: true }),
  )

  const loadData = useCallback(async () => {
    dispatch(setValues({ ...initialState }))

    if (editId == null) {
      return
    }

    try {
      const values = await getRowApi.performRequest({ id: editId })

      dispatch(setValues(values))
    } catch (_e) {
      onClose(true)
    }
  }, [getRowApi, editId, onClose])

  const saveApi = useApi(
    () => ({ action: 'purchaseOrders_update' }),
    null,
    () => ({ throws: true }),
  )

  const saveData = useCallback(async () => {
    try {
      const props = { id: editId, ...state }

      if (!canEditDiscount) {
        delete props.discount
      }

      const ret = await saveApi.performRequest(props)

      // If status changes, we may need to redirect
      const deepLink = linkTo(ret.id, ret.order_status)
      if (document.location.pathname !== deepLink) {
        navigate(deepLink)
        return
      }

      onClose(true)
    } catch (_e) {
      /* Ignore */
    }
  }, [saveApi, editId, state, onClose, canEditDiscount, navigate])

  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (isOpen && prevIsOpen !== isOpen) {
      loadData()
    }
  }, [isOpen, loadData, prevIsOpen])

  const isLoading = saveApi.isLoading || getRowApi.isLoading
  const error = getRowApi.error || saveApi.error

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setValues({ [e.target.name]: e.target.value }))
  }, [])

  const handleChangeStatus = useCallback((option: SelectOption) => {
    dispatch(setValues({ order_status: option ? option.value : null }))
  }, [])

  const handleSubmit = useCallback(() => saveData(), [saveData])

  return (
    <EditPanel
      caption="Order Details"
      onSubmit={isLoading ? null : handleSubmit}
      {...{ isOpen, onClose, isLoading }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <EditPanelCard caption="Order Details" stateId="order_details">
          {error ? (
            <Row>
              <Col className="text-danger">{error}</Col>
            </Row>
          ) : null}
          <Row>
            <Col>
              <FormGroup className="mb-0">
                <Label className="mb-0" htmlFor="invoice_number">
                  Invoice Number
                </Label>
                <Input
                  onChange={handleChange}
                  value={state.invoice_number}
                  type="text"
                  id="invoice_number"
                  name="invoice_number"
                  placeholder="Invoice Number"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="mb-0">
                <Label className="mb-0" htmlFor="order_status">
                  Custom Order Status
                </Label>
                <Select<SelectOption>
                  placeholder="Select Status"
                  value={poStatusOptions.find(
                    (o) => o.value === state.order_status,
                  )}
                  onChange={handleChangeStatus}
                  options={poStatusOptions}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="mb-0">
                <Label className="mb-0" htmlFor="order_date">
                  Order Date
                </Label>
                <Input
                  onChange={handleChange}
                  value={state.order_date}
                  type="date"
                  id="order_date"
                  name="order_date"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="mb-0">
                <Label className="mb-0" htmlFor="discount">
                  Global Discount in %
                </Label>
                <Input
                  onChange={handleChange}
                  value={state.discount}
                  type="number"
                  min={0}
                  max={100}
                  step={0.01}
                  id="discount"
                  name="discount"
                  pattern="\d+(.\d{1,2})?"
                  disabled={!canEditDiscount}
                />
              </FormGroup>
            </Col>
          </Row>
        </EditPanelCard>
      )}
    </EditPanel>
  )
}

export default OrderDetailsEditor
